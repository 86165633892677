import '../scss/styles.scss';

/**
 * drawer
 */

const drawerOpenTarget = document.querySelector('.js-drawer-open');
const drawerCloseTarget = document.querySelectorAll('.js-drawer-close');
const body = document.querySelector('.body');

drawerOpenTarget.addEventListener('click', function () {
  body.classList.add('is-viewNav');
});

drawerCloseTarget.forEach((el) => {
  el.addEventListener('click', function () {
    body.classList.remove('is-viewNav');
  });
});


/**
 * swiper
 */

import Swiper from 'swiper/bundle'
import 'swiper/swiper-bundle.min.css';


// top mv slider
window.addEventListener("load", () => {
  if (document.querySelector('.mv-slider')) {
    const mvSliderOption = {
      loop: true,
      speed: 1000,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      pagination: {
        el: ".swiper-pagination", // ページネーションのクラス名を指定
        type: "fraction", // ページネーションのtypeを指定
      }
    }

    const swiper = new Swiper('.mv-slider', mvSliderOption); // eslint-disable-line

    swiper.on('slideChangeTransitionStart', function () {
      console.log('start');
      document.querySelector('.mv-slider').classList.remove('is-bar-anime-start')
    });

    swiper.on('slideChangeTransitionEnd', function () {
      console.log('end');
      document.querySelector('.mv-slider').classList.add('is-bar-anime-start')
    });
  }

// top pickup slider
  if (document.querySelector('.js-pickup-slider')) {
    if (document.querySelector('.js-pickup-slider')) {
      const pickupSliderOption = {
        loop: false,
        autoplay: false,
        slidesPerView: 2,
        spaceBetween: 40,
        navigation: {
          nextEl: '.pickupSlider-button-next',
          prevEl: '.pickupSlider-button-prev',
        },
        breakpoints: {
          980: {
            slidesPerView: 3,
          },
        }
      }

      const swiper = new Swiper('.js-pickup-slider', pickupSliderOption); // eslint-disable-line
    }
  }
});

/**
 * スムーススクロール
 */
window.addEventListener("load", () => {
  const smoothScrollTrigger = document.querySelectorAll('a[href^="#"]');

  for (let i = 0; i < smoothScrollTrigger.length; i++) {
    smoothScrollTrigger[i].addEventListener('click', (e) => {
      e.preventDefault();
      let href = smoothScrollTrigger[i].getAttribute('href');
      let targetElement = document.getElementById(href.replace('#', ''));
      const rect = targetElement.getBoundingClientRect().top;
      const offset = window.pageYOffset;
      const gap = document.querySelector('.header').clientHeight + 30;
      const target = rect + offset - gap;
      window.scrollTo({
        top: target,
        behavior: 'smooth',
      });
    });
  }
});

/*
 accordion
 */
const accrdionMaxHeight = () => {
  const content = document.querySelectorAll(".js-accordion01");
  content.forEach((el) => {
    const height = el.scrollHeight;
    el.style.setProperty("--max-height", height + "px");
  });
}

window.addEventListener("resize", () => {
  if (document.querySelector('.js-accordion01')) {
    accrdionMaxHeight();
  }
})

window.addEventListener("load", () => {
  if (document.querySelector('.js-accordion01')) {
    accrdionMaxHeight();

    const accTarget = document.querySelectorAll('.js-accTarget');

    accTarget.forEach((elm) => {
      elm.addEventListener('click', () => {
        elm.classList.toggle('is-open');
        elm.nextElementSibling.classList.toggle('is-open');
      })
    });
  }

  if (document.querySelector('.js-accordion02')) {
    const accToggle02 = document.querySelectorAll('.js-acc02Toggle');
    const accTarget02 = document.querySelector('.js-accordion02');
    console.log(accTarget02)

    accToggle02.forEach((elm) => {
      elm.addEventListener('click', () => {
        console.log('click')
        accTarget02.classList.toggle('is-open');
      })
    });
  }
});